import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import {
  getCheapestAvailabilityDeparture,
  getCheapestDeparture,
  getDateDifferenceInDays,
  mapLocaleToCurrency,
  mapLocaleToMarket
} from '@utils';
import { get } from '@utils/http';
import { getRoundedDiscountPercentage } from '@src/utils/voyage/getRoundedDiscountPercentage';
import { excludeGTY } from '@constants/unspecifiedCabins';

const hasDiscount = (
  price: number | undefined,
  originalPrice: number | undefined
) => price && originalPrice && originalPrice > price;

const useCruiseAvailability = (
  availability: Contentful.TAvailability | undefined | null,
  packageCodes: string[],
  locale: TLocale
) => {
  const shouldFetchPriceFromPG = !availability;

  const [departureDate, setDepartureDate] = useState<string | undefined>(
    undefined
  );
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [strikeThroughPrice, setStrikeThroughPrice] = useState<
    number | undefined
  >(undefined);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);

  const memoizedAvailability = useMemo(
    () =>
      availability?.availabilityData?.voyages.map((x) => ({
        price: x.price,
        strikeThroughPrice: x.strikethroughPrice
      })) ?? [],
    [availability]
  );

  const params = new URLSearchParams({
    packageCodes: JSON.stringify(packageCodes),
    market: mapLocaleToMarket(locale),
    currency: mapLocaleToCurrency(locale)
  });

  const { data, error } = useSWR(
    shouldFetchPriceFromPG ? `/api/search/voyages?${params.toString()}` : null,
    get<PG.TPackageVoyage[]>(),
  {
    onSuccess: () => setLoading(false),
    onError: () => setLoading(false)
  }
  );

  const setData = useCallback(
    (
      p: number | undefined, // price
      sp: number | undefined, // strikethrough price
      isGTY: boolean = false,
      dDate: string | undefined, // departure date
      dur: number | undefined // duration
    ) => {
      setPrice(p);
      setStrikeThroughPrice(!isGTY && hasDiscount(p, sp) ? sp : undefined);
      setDiscountPercentage(
        !isGTY && p && sp ? getRoundedDiscountPercentage(p, sp) : 0
      );
      setDepartureDate(dDate);
      setDuration(dur);
    },
    []
  );

  useEffect(() => {
    const cheapestDepartureFromContentful =
      getCheapestAvailabilityDeparture(availability);
    const priceFromContentful = cheapestDepartureFromContentful?.price;
    const strikeThroughPriceFromContentful =
      cheapestDepartureFromContentful?.strikethroughPrice;
    const isGTY = cheapestDepartureFromContentful?.cabinsWithPromotions
      .map((c) => excludeGTY(c.promotions))
      .some((s) => s === false);

    setData(
      priceFromContentful,
      strikeThroughPriceFromContentful,
      isGTY,
      cheapestDepartureFromContentful?.date,
      availability?.availabilityData?.duration ?? undefined
    );
    setLoading(false)
  }, [memoizedAvailability]);

  useEffect(() => {
    if (data) {
      const cheapestDepartureFromPG = getCheapestDeparture(data);
      const priceFromPG = cheapestDepartureFromPG?.priceDetail.price;
      const strikeThroughPriceFromPG =
        cheapestDepartureFromPG?.priceDetail.strikeThroughPrice;

      const durationFromPG = data?.[0]
        ? getDateDifferenceInDays(
            new Date(data[0].departureDate),
            new Date(data[0].arrivalDate ?? '')
          )
        : undefined;

      setData(
        priceFromPG,
        strikeThroughPriceFromPG,
        false,
        cheapestDepartureFromPG?.departureDate,
        durationFromPG
      );
    }
  }, [data]);
  const isLoading = loading || (shouldFetchPriceFromPG && !data && !error);
  const isSoldOut = !loading && !price;

  return {
    price,
    strikeThroughPrice,
    departureDate,
    discountPercentage,
    duration,
    isLoading,
    isSoldOut,
  };
};

export default useCruiseAvailability;
