export const unspecifiedCabinCodes: string[] = [
  'SUS',
  'USP',
  'USPL',
  'USPO',
  'USPS',
  'USPX',
  'USPY',
  'USSY',
  'USXY',
  'UXYA',
  'OPET',
  'SPEO',
  'SUB',
  'SUI',
  'SUI4',
  'SUL',
  'SUL3',
  'SUO',
  'SUO3',
  'SYA3',
  'UNI3',
  'UNI4',
  'UNL3',
  'UNO3',
  'UNT3',
  'USP3',
  'USP4',
  'USPB',
  'USPI',
  'USPM',
  'USPT',
  'UYA3',
  'V J2',
  'V J3',
  'V L2',
  'V M2',
  'V MG',
  'V MX',
  'V N2',
  'V O2',
  'V P2',
  'V P3',
  'V Q2',
  'V QJ',
  'V U2',
  'VPET',
  'VYA3',
  'SGO',
  'SSD',
  'SUB1',
  'SUB3',
  'SUI1',
  'SUI3',
  'SUL1',
  'SUL4',
  'SUO1',
  'SUO4',
  'SUS4',
  'UFNL',
  'UGO',
  'UNB1',
  'UNI1',
  'UNL1',
  'UNL4',
  'UNO1',
  'UNO4',
  'UNS4',
  'UNT1',
  'UNT4',
  'USD',
  'USIF',
  'USP1',
  'V GO',
  'V J1',
  'V L1',
  'V L3',
  'V L4',
  'V M4',
  'V N4',
  'V SD',
  'V U1',
  'SUBD',
  'SUS3',
  'UNB3',
  'UNS3',
  'USPD',
  'V M3',
  'V N3',
  'V O3',
  'V U3',
  'VJ2D',
  'VP2D',
  'VU2D',
  'DPET',
  'SPED',
  'SUD',
  'SUD1',
  'SUD4',
  'USN1',
  'USN4',
  'USNF',
  'V A2',
  'V N1',
  'V Q4',
  'SYA',
  'UYA',
  'VMG4',
  'UNF1',
  'IPET',
  'SGO3',
  'SPEI',
  'SSD3',
  'SSD5',
  'SSI3',
  'UGO3',
  'USD3',
  'USD5',
  'USI3',
  'V A1',
  'V A3',
  'V A4',
  'VGO3',
  'VSD3',
  'V AJ',
  'V Q3',
  'V YA'
];

export const unspecifiedCabin = (cabinCategory: string): boolean =>
  unspecifiedCabinCodes.some((s) => s === cabinCategory);

export const excludeGTY = (
  promoCodes: string[],
  locale?: string
): boolean | null => {
  if (!promoCodes || promoCodes.length === 0) {
    if (locale && locale === 'en-us') {
      return true;
    }
    return null;
  }
  return !promoCodes.some((p) => p.includes('GTY'));
};
