import clsx from 'clsx';

const InformationBubble = ({
  tip,
  position,
  isVisible
}: {
  isVisible: boolean;
  tip: string | string[];
  position: { top?: string; left?: string; right?: string; bottom?: string };
}) => (
  <div className={clsx('fixed z-50', { hidden: !isVisible })} style={position}>
    <div className={'bg-black bg-opacity-70 relative rounded-5xl p-6'}>
      <p className="flex flex-col text-white">
        {Array.isArray(tip) && tip.map((t) => <span key={t}>{t}</span>)}
        {!Array.isArray(tip) && tip}
      </p>
    </div>
    <span
      className={clsx(
        'inline-block w-0 h-0 absolute top-[-10px] border-[10px] border-t-0 border-transparent border-opacity-70 border-b-black',
        {
          'left-[15px]': position.left,
          'right-[15px]': position.right
        }
      )}
    />
  </div>
);

export default InformationBubble;
