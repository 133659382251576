/**
 * Validates an ISO date string (YYYY-MM-DD)
 */
const validISODateStr = (dateStr: string): boolean => {
  const [year, month, day] = dateStr.split('-').map((p) => parseInt(p, 10));

  // All must be present
  if (!(year && month && day)) {
    return false;
  }

  const date = new Date(year, month - 1, day);

  if (!(date instanceof Date)) {
    return false;
  }
  if (Number.isNaN(date.valueOf())) {
    return false;
  }

  // Check that created date is the same as inputs.
  // If they are not then given inputs where not a
  // valid calendar date.
  if (date.getFullYear() !== year) {
    return false;
  }
  if (date.getMonth() !== month - 1) {
    return false;
  }
  if (date.getDate() !== day) {
    return false;
  }

  return true;
};
export default validISODateStr;
