export const getMinimumPrice = (
  voyages?: PG.TPackageVoyage[],
  priceProp: 'price' | 'strikeThroughPrice' = 'price'
): number | undefined => {
  if (!voyages || voyages.length === 0) {
    return undefined;
  }

  const voyagesWithPrice = voyages.filter((v) => v.priceDetail[priceProp] > 0);

  if (voyagesWithPrice.length === 0) {
    return undefined;
  }

  return Math.min(...voyagesWithPrice.map((v) => v.priceDetail[priceProp]));
};

export default getMinimumPrice;

export const getCheapestDeparture = (
  voyages?: PG.TPackageVoyage[],
  priceProp: 'price' | 'strikeThroughPrice' = 'price'
): PG.TPackageVoyage | undefined => {
  if (!voyages || voyages.length === 0) {
    return undefined;
  }

  const lowestPrice = getMinimumPrice(voyages, priceProp);

  return lowestPrice
    ? voyages.find((voyage) => voyage.priceDetail[priceProp] === lowestPrice)
    : undefined;
};
