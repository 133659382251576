import validISODateStr from './validISODateStr';

/* In the case of cruise departure dates (for example) a date string 
such as: '2023-04-14T00:00:00' does not have local timezone information 
which means that when converting to a Date() object, the timezone may result 
in a date that actually is 13.04.2023.

This utility attempts to convert that date string to a UTC date bang on 12pm
so that no local timezone offset could change the actual day.
 */
const utcDateFromString = (dateString: string): Date => {
  const valid = validISODateStr(dateString);
  if (valid) {
    const [year, month, day] = dateString
      .split('-')
      .map((p) => parseInt(p, 10));
    return new Date(Date.UTC(year, month - 1, day, 12, 0, 0, 0));
  }
  /* eslint-disable-next-line no-console */
  console.log(
    'Date string conversion from ISO to UTC did not have a valid ISO date. Date conversion will fallback to local timezone'
  );
  return new Date(dateString);
};

export default utcDateFromString;
